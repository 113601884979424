import React from "react"
import { Spring, animated, config } from 'react-spring'
import Layout from "../components/layout"
import SEO from "../components/seo"
import mailIcon from "../images/mail-icon.png"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <nav>
      <Spring native config={{delay: 450, tension: 280, friction: 120}} from={{ opacity: 0, transform: 'translate3d(0,-20px,0)' }} to={{ opacity: 1, transform: 'translate3d(0,0,0)' }}>
		  	{styles => 
          <animated.div style={styles}>
          <span><img className="mail-icon" src={mailIcon} alt="Email Us"/><a href="mailto:hello@designscience.co">hello@designscience.co</a></span>
          </animated.div>}
        </Spring>
      </nav>
    

    <div className="main-container">
    <Spring native config={{delay: 250, tension: 280, friction: 120}} from={{ opacity: 0, transform: 'translate3d(0,20px,0)' }} to={{ opacity: 1, transform: 'translate3d(0,0,0)' }}>
		  	{styles => 
          <animated.div style={styles}> 
          <h1 className="main-heading">Look's like you've ventured too far. <br/> There's nothing here.</h1>
          </animated.div>}
      </Spring>

    </div>
  </Layout>
)

export default NotFoundPage
